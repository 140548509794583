import React from 'react'
import Layout from '../components/_App/Layout'
import Seo from '../components/_App/Seo'
import NavbarTwo from '../components/_App/NavbarTwo'
import PageBanner from '../components/Common/PageBanner'
import ServiceDetails from '../components/ServiceDetails/ServiceDetails'
import Footer from '../components/_App/Footer'
import Copyright from '../components/_App/Copyright'

const ServiceDetailsPage = () => {
    return (
        <Layout>
            <Seo title="Service Details" />

            <NavbarTwo />

            <PageBanner 
                pageTitle="Service Details" 
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="Service Details" 
            />

            <ServiceDetails />

            <Footer />

            <Copyright />
        </Layout>
    )
}

export default ServiceDetailsPage
