import React from 'react'
import { Link } from 'gatsby'

// Images 
import servicesdetailsImg from '../../assets/images/inner-images/sd-1.jpg'
import servicesdetailsImg2 from '../../assets/images/inner-images/sd2.jpg'
import servicesdetailsImg3 from '../../assets/images/inner-images/pd2.jpg'


const ServiceDetails = () => {
    return (
        <section className="service-details ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4">
                        <div className="sidebar-area">
                            <div className="sidebar-card sidebar-category">
                                <h3>Service Category</h3>
                                <ul>
                                    <li><Link className="active" to="/service-details"><span>Advanced Media Analytics</span> <i className="fas fa-arrow-right"></i></Link></li>
                                    <li><Link to="/service-details"><span>Web Development</span> <i className="fas fa-arrow-right"></i></Link></li>
                                    <li><Link to="/service-details"><span>Organic Long Term SEO</span> <i className="fas fa-arrow-right"></i></Link></li>
                                    <li><Link to="/service-details"><span>Digital Marketing</span> <i className="fas fa-arrow-right"></i></Link></li>
                                    <li><Link to="/service-details"><span>Management For Business</span> <i className="fas fa-arrow-right"></i></Link></li>
                                </ul>
                            </div>
                            <div className="sidebar-card mt-30 sidebar-contact">
                                <h3>Contact Us</h3>
                                <ul>
                                    <li><i className="fas fa-phone"></i> Call:  <a href="tel:+029222110"> +(029-22-2110)</a></li>
                                    <li><i className="fas fa-map-marker-alt"></i> Address:  <a href="https://goo.gl/maps/igV8NqPfViMJx2x69" rel="noreferrer"> 32 Street Name, New York, USA</a></li>
                                    <li><i className="far fa-envelope"></i> Email:  <a href="mailto:hello@glit.com"> hello@glit.com</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-8">
                        <div className="details teaxt-area pt-30 pl-20">
                            <img 
                                className="details-main-img" 
                                src={servicesdetailsImg}
                                alt="services details"
                            />
                            <h3 className="details-page-title">How Can Digital Agencies Help Your Business Growth</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra make acumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod.</p>
                            <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed perspiciatis unde omnis iste natus error sit voluptatem accusantium laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae explicabo.</p>
                            <div className="sd-img-area">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                        <div className="sd-img">
                                        <img 
                                            src={servicesdetailsImg2}
                                            alt="services details"
                                        />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                        <div className="sd-img">
                                        <img 
                                            src={servicesdetailsImg3}
                                            alt="services details"
                                        />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="sd-list-area">
                                <h3 className="details-page-title">Considerable Facts</h3>
                                <ul>
                                    <li><i className="fas fa-check"></i> Financial Marketing</li>
                                    <li><i className="fas fa-check"></i> Vision & Values</li>
                                    <li><i className="fas fa-check"></i> Leadership</li>
                                    <li><i className="fas fa-check"></i> Sustainability</li>
                                    <li><i className="fas fa-check"></i> Handover & Support</li>
                                    <li><i className="fas fa-check"></i> Digital Marketing</li>
                                    <li><i className="fas fa-check"></i> SEO Marketing</li>
                                    <li><i className="fas fa-check"></i> Facebook Marketing</li>
                                    <li><i className="fas fa-check"></i> Online Marketing</li>
                                    <li><i className="fas fa-check"></i> Web Development</li>
                                    <li><i className="fas fa-check"></i> Web Design</li>
                                </ul>
                            </div>
                            <h3 className="details-page-title mt-30">Handover & Support</h3>
                            <p>Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequ nesciunt. Neque porro quisquam est, it dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p>
                            <div className="support-list">
                                <ul>
                                    <li><i className="fas fa-check"></i> Product Engineering</li>
                                    <li><i className="fas fa-check"></i> Page Load Details (time, size, number of requests)</li>
                                    <li><i className="fas fa-check"></i> Big Data & Analytics</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServiceDetails
